import React from 'react';
import { useEffect, useState, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Standings from './Standings';
import { Box, IconButton } from '@mui/material';
import { Divider } from '@mui/material';
import { Stack } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import TimingView from './TimingView';
import ClassView from './ClassView';
import { API_IP } from './Vars';
import UserContext from './UserContext';
import { getAuthHeader } from './Utils';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import FirstTimeUserExperience from './FirstTimeUserExperience';

const RaceStateContainer = ( {children, selectedRace, selectedDriver, settingsClickHandler, selectedView, changeSelectedDriverHandler, changeSelectedViewHandler }) => {
    const { authUser, logout } = useContext(UserContext);

    const [myRacers, setMyRacers] = React.useState([]);
    const [capitalizedView, setCapitalizedView] = React.useState("");
    const headerRef = React.useRef(null);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [classRacers, setClassRacers] = useState({});
    const [raceState, setRaceState] = useState({});

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm')); // < 600px
    const isMoreThanSmall = useMediaQuery(theme.breakpoints.up('sm')); // >= 600px
    const isMedium = useMediaQuery(theme.breakpoints.down('md')); // < 1000px
    const isLarge = useMediaQuery(theme.breakpoints.up('sm')); // >= 600px
    const isTall = useMediaQuery('(min-height:500px)'); // >= 500px height

    const advanceToNextView = () => {
        if (!selectedView) {
            return;
        }

        const viewList = ["standings", "class", "overall"];
        const viewIndex = viewList.indexOf(selectedView);
        if (viewIndex == -1) {
            return;
        }

        const nextViewIndex = (viewIndex + 1) % viewList.length;
        changeSelectedViewHandler(viewList[nextViewIndex]);
    };

    const getClassRacers = (racers) => {
        const classColors = [
            "classOne",
            "classTwo",
            "classThree",
            "classFour",
            "classFive",
            "classSix",
            "classSeven",
            "classEight"
        ];

        var classRacers = {};
        if (!racers || racers.length == 0) {
            return classRacers;
        }
        var classCount = 0;
        racers.forEach((racer) => {
            if (!classRacers[racer.class]) {
                classRacers[racer.class] = {};
                classRacers[racer.class].color = classColors[classCount % classColors.length];
                classRacers[racer.class].racers = [];
    
                classCount++;
            }
            classRacers[racer.class].racers.push(racer);
        });
    
        return classRacers;
    };

    const getHeaderBackgroundStyle = (raceState) => {
        if (!raceState || !raceState.flag || raceState.flag.length == 0) {
            return {};
        }

        let returnStyle = {};
        switch (raceState.flag) {
            case "green":
                returnStyle.backgroundColor = "green";
                returnStyle.color = "white";
                break;
            case "yellow":
                returnStyle.backgroundColor = "yellow";
                returnStyle.color = "black";
                break;
            case "red":
                returnStyle.backgroundColor = "red";
                returnStyle.color = "white";
                break;
            case "blue":
                returnStyle.backgroundColor = "blue";
                returnStyle.color = "white";
                break;
            case "checkered":
                returnStyle.backgroundImage = "url('/checkered-dark-sm.png')";
                returnStyle.backgroundRepeat = "repeat";
                returnStyle.color = "white";
                break;
            default:
                break;
        }        
        return returnStyle;
    }

    useEffect(() => {
        if (headerRef.current) {
            // console.log("RaceStateContainer: header height: " + headerRef.current.offsetHeight);
            setHeaderHeight(headerRef.current.offsetHeight);
        }
    }, []);

    useEffect(() => {
        if (!selectedRace) {
            return;
        }

        const fetchRaceData = () => {
            fetch(`${API_IP}:3005/race/${selectedRace.path}`,{
                headers: getAuthHeader(authUser)
            })
            .then((response) => {
                if (!response.ok) {
                    console.log(`fetchRaceData response HTTP code: ${response.status}, response: ${JSON.stringify(response)}`);
                    if (response.status == 401) {
                        console.log("fetchRaceData: Unauthorized, logging out");
                        logout();
                    }
                    return null;
                }
                // console.log(`Reponse HTTP code: ${response.status}`);
                return response.json();
            })
            .then((jsonResponse) => {   
                // console.log("RaceStateContainer: received racers: " + jsonResponse ? jsonResponse.length : "None");             
                setMyRacers(jsonResponse && jsonResponse.racers ? jsonResponse.racers : []);
                setRaceState(jsonResponse && jsonResponse.raceState ? jsonResponse.raceState : {});
                setClassRacers(getClassRacers(jsonResponse && jsonResponse.racers ? jsonResponse.racers : []));
            })
            .catch((error) => {
                console.log("RaceStateContainer: fetch race error: " + error);
                setMyRacers([]);
                setClassRacers({});
                setRaceState({});
            });
        };

        fetchRaceData();

        const intervalId = setInterval(() => {
            fetchRaceData();
        }, 1000);

        return () => {
            clearInterval(intervalId);
        }
    }, [selectedRace]);
    
    // This second useEffect handler is to queue up the race for processing to handle waiting for a race that's about to start
    useEffect(() => {
        if (!selectedRace) {
            return;
        }

        const intervalId = setInterval(() => {
            // console.log(`Queueing race \"${selectedRace.name}\" for processing`)
            fetch(`${API_IP}:3005/queuerace/${selectedRace.source}/${selectedRace.path}`,{
                headers: getAuthHeader(authUser)
            })
            .then((response) => {
                // console.log(`Queue Race Response HTTP code: ${response.status}`);
                // return response.json();
                if (!response.ok) {
                    console.log(`queuerace response HTTP code: ${response.status}, response: ${JSON.stringify(response)}`);
                    if (response.status == 401) {
                        console.log("queuerace: Unauthorized, logging out");
                        logout();
                    }
                    return null;
                }
            })
            .then((jsonResponse) => {
                // console.log(JSON.stringify(jsonResponse));
            })
            .catch((error) => {
                console.log("RaceStateContainer: queue race error: " + error);
            });
        }, 5000);

        return () => {
            clearInterval(intervalId);
        }
    }, [selectedRace]);

    useEffect(() => {
        if (!selectedView) {
            return;
        }

        setCapitalizedView(selectedView.charAt(0).toUpperCase() + selectedView.slice(1));
    }, [selectedView]);

    return (
        <Box sx={{ padding: 1, height: '100vh', overflow: 'hidden' }}>
            <Box ref={headerRef}>
                <Stack 
                    direction="row" 
                    spacing={1} 
                    divider={<Divider orientation="vertical" flexItem />}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1, ...(getHeaderBackgroundStyle(raceState)) }}>
                    <Typography noWrap variant="headerText" component="span" align='center' 
                        sx={{ textDecoration: 'underline', width: '6rem', ...(isSmall || (isMoreThanSmall && !isTall) ? { fontSize: '1rem' } : {}) }}
                        onClick={() => advanceToNextView()}>
                        { capitalizedView }
                    </Typography>
                    { isLarge && selectedRace ? (
                        <Typography noWrap variant="headerText" component="span" sx={{ ...(isMoreThanSmall && !isTall ? { fontSize: '1rem' } : {}) }}>
                            { selectedRace ? selectedRace.name : "Pick a race" }
                        </Typography>
                    ) : null }
                    { raceState && raceState.sessionName ? 
                        <Typography noWrap variant="headerText" component="span" sx={{ ...(isSmall || (isMoreThanSmall && !isTall) ? { fontSize: '1rem' } : {}) }}>{raceState.sessionName}</Typography> : 
                        null }                    
                    { isLarge && myRacers && selectedRace ? (
                        <Typography noWrap variant="headerText" component="span" align='center' sx={{ width: '6rem', ...(isMoreThanSmall && !isTall ? { fontSize: '1rem' } : {}) }}>
                            Racers: {myRacers && myRacers.length > 0 ? myRacers.length : "none"}
                        </Typography>
                    ) : null }
                    { raceState && raceState.time ? 
                        <Typography noWrap variant="headerText" component="span" align='center' sx={{ minWidth: '3.5rem', ...(isSmall || (isMoreThanSmall && !isTall) ? { fontSize: '1rem' } : {}) }}>{raceState.time}</Typography> : 
                        null }
                    { raceState && raceState.sessionType && raceState.sessionType.length > 0 ? 
                        <Typography noWrap variant="headerText" component="span" align='center' sx={{ backgroundColor: 'gray', borderRadius: 1, minWidth: '1.5rem', ...(isSmall || (isMoreThanSmall && !isTall) ? { fontSize: '1rem' } : {}) }}>&nbsp;{raceState.sessionType[0].toUpperCase()}&nbsp;</Typography> : 
                        null }
                    <IconButton aria-label="settings" size={isSmall || (isMoreThanSmall && !isTall) ? "small" : "large"} onClick={() => { settingsClickHandler() }}>
                        <TuneIcon sx={{ color: raceState && raceState.flag && raceState.flag == "yellow" ? "black" : "inherit" }}/>
                    </IconButton>
                </Stack>
            </Box>
            <Divider orientation="horizontal" flexItem /> 
            { !selectedRace ? <FirstTimeUserExperience message="Welcome to Black Box! Select a race using the top right menu to get started."/> : null }
            { selectedRace && selectedView == "standings" ? <Standings racers={myRacers} classRacers={classRacers} race={selectedRace} driver={selectedDriver} changeSelectedDriverHandler={ changeSelectedDriverHandler } headerHeight={headerHeight} /> : null }
            { selectedRace && selectedView == "timing" ? <TimingView racers={myRacers} classRacers={classRacers} race={selectedRace} driver={selectedDriver} headerHeight={headerHeight} raceState={raceState} /> : null }
            { selectedRace && selectedView == "class" ? <ClassView racers={myRacers} classRacers={classRacers} race={selectedRace} driver={selectedDriver} headerHeight={headerHeight} raceState={raceState} isOverall={false}/> : null }
            { selectedRace && selectedView == "overall" ? <ClassView racers={myRacers} classRacers={classRacers} race={selectedRace} driver={selectedDriver} headerHeight={headerHeight} raceState={raceState} isOverall={true}/> : null }
        </Box>
    );
};

export default RaceStateContainer;
