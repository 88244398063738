import React from 'react';
import { useState, useEffect, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { API_IP } from './Vars';
import UserContext from './UserContext';
import { getAuthHeader } from './Utils';
import { FormControlLabel, Switch } from '@mui/material';
import { amendRaceList } from './Utils';

const SelectRaceDialog = ( { dialogOpen, handleClose, handleDialogSelectedRace, curSelectedRace } ) => {
    const { authUser, logout } = useContext(UserContext);

    const [selectedRace, setSelectedRace] = useState(null);
    const [lastError, setLastError] = useState("");
    const [serverRaceList, setServerRaceList] = useState([]);
    const [raceList, setRaceList] = useState([]);
    const [liveOnly, setLiveOnly] = useState(false);

    // Race list schema looks like this:
    // {
    //     "name": "SPEED SF - Thunderhill West 4/6 - 4/7",
    //     "path": "speed-sf-thunderhill-west-4-6-4-7",
    //     "imageUrl": "https://res.cloudinary.com/racehero/image/upload/c_pad,g_center,h_50,w_50/v1673651640/4b6de9c8832bd808ee9db52ef8218ecf.jpg",
    //     "series": "Speed SF Track Events",
    //     "date": "April  6, 2024",
    //     "track": "Thunderhill West",
    //     "islive": "yes",
    //     "source": "rh"
    // },

    useEffect(() => {
        refreshRaces();
    }, []);

    useEffect(() => {
        // Now populate the list with loaded races
        updateRacesList();
    }, [serverRaceList, liveOnly]);

    const refreshRaces = () => {
        // console.log("SelectRaceDialog.refreshRaces");
        fetch(`${API_IP}:3005/racelist`,{
            headers: getAuthHeader(authUser)
        })
        .then((response) => {
            // console.log(`Reponse HTTP code: ${response.status}`);
            if (!response.ok) {
                // console.log(`racelist response HTTP code: ${response.status}, response: ${JSON.stringify(response)}`);
                if (response.status == 401) {
                    console.log("racelist: Unauthorized, logging out");
                    logout();
                }
                return null;
            }
            return response.json();
        })
        .then((jsonResponse) => {
            // console.log("SelectRaceDialog.refreshRaces: " + JSON.stringify(jsonResponse));
            const amendedPayload = amendRaceList(jsonResponse);
            setServerRaceList(amendedPayload.racelist);
            setLastError("")
        })
        .catch((error) => {
            // console.log("SelectRaceDialog: refreshRaces error: " + error);
            setServerRaceList([]);
            setLastError(error.message);
        });
    };

    function updateRacesList() {
        var myRacesList = [];
        if (serverRaceList && serverRaceList.length > 0) {  
            myRacesList = serverRaceList.filter((race) => liveOnly ? race.islive == 'yes' : true );
        }

        setRaceList(myRacesList);
    }

    function handleDialogClose() {
        // console.log('dialog closed');
        handleClose();
        // setDialogMenu(false);
    }

    function handleDialogSave(selectedRace) {
        // console.log('dialog save');

        console.log(`Queueing race \"${selectedRace.name}\" for processing`)
        fetch(`${API_IP}:3005/queuerace/${selectedRace.source}/${selectedRace.path}`,{
            headers: getAuthHeader(authUser)
        })
        .then((response) => {
            console.log(`Queue Race Response HTTP code: ${response.status}`);
            if (!response.ok) {
                console.log(`Queue Race response HTTP code: ${response.status}, response: ${JSON.stringify(response)}`);
                if (response.status == 401) {
                    console.log("Queue Race: Unauthorized, logging out");
                    logout();
                }
                return null;
            }
            return response.json();
        })
        .then((jsonResponse) => {
            // console.log(JSON.stringify(jsonResponse));
        });

        handleDialogSelectedRace(selectedRace);
        handleClose();
    }

    function handleSelectedRace(theSelectedRace) {
        console.log("SelectRaceDialog.handleSelectedRace: " + theSelectedRace.name);
        setSelectedRace(theSelectedRace);  
        handleDialogSave(theSelectedRace);
    };

    function handleLiveOnlySwitchChange(event) {
        setLiveOnly(event.target.checked);
    }
    
    function renderRacesList(theRaceList) {    
        var myListItems = null;
        if (theRaceList.length < 1) {
            myListItems = (
                <>
                    <ListItem>
                        <ListItemText
                            primary={lastError ? "Error" : "Fetching races..." }
                            secondary={lastError ? lastError : "Please wait"}
                        />
                    </ListItem>
                </>
            );
        }
        else {
            myListItems = (
                <>
                    { theRaceList.map(race => (
                        <ListItem key={race.path}>
                            <ListItemButton onClick={() => handleSelectedRace(race)} selected={curSelectedRace && curSelectedRace.path == race.path}>
                                <ListItemIcon>
                                    { race.islive == 'yes' ? <PlayCircleIcon /> : <CloseIcon /> }
                                </ListItemIcon>
                                <ListItemText
                                    primary={race.sourceDisplay + ": " + race.name + (race.islive == 'yes' ? " (LIVE)" : "")}
                                    secondary={race.track}
                                />
                            </ListItemButton>
                        </ListItem>                
                    ))
                    }
                </>
            );
        }

        return myListItems;
    }

    return (
        <div>
            <Dialog
                fullScreen
                open={dialogOpen}
                onClose={handleDialogClose}        
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleDialogClose}
                    aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Select Race
                    </Typography>
                    {/* <Button autoFocus color="inherit">
                        live
                    </Button> */}
                    <FormControlLabel control={<Switch onChange={handleLiveOnlySwitchChange} />} label="Show Live only" />
                </Toolbar>
                </AppBar>
                <List>
                    { renderRacesList(raceList) }
                </List>
            </Dialog>
        </div>
    );
};

export default SelectRaceDialog;
